<!-- COMPONENTE DE SLIDER DE PRODUCTOS EN MÓVIL -->

<template v-if="products">
  <q-item class="justify-between">
    <router-link to="/products">
      <h6 class="q-mt-xs q-mb-xs q-ml-md text-weight-bold text-left">
        {{ $t("Home.catalog") }}
      </h6>
    </router-link>
    <router-link to="/products">
      <q-card-section
        class="underline-text title-products q-pa-sm text-weight-bold"
        
      >
        {{ $t("Home.see-all") }}
      </q-card-section>
    </router-link>
  </q-item>

  <!-- Slider de productos -->

  <div class="q-pa-xs" style="width: 100%" v-if="products.length > 0">
    <q-carousel
      v-model="slide"
      transition-prev="slide-right"
      transition-next="slide-left"
      animated
      swipeable
      control-color="primary"
      padding
      height="250px"
      class="rounded-borders"
    >
      <q-carousel-slide :name="1" class="row no-wrap">
        <div
          class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap"
          v-for="product in products.slice(0, 10)"
          :key="product.id"
          v-show="product.visible"
        >
          <div class="column q-mb-lg">
            <div>
              <!-- Si hay más de una imagen, agarra la primera; si no, pone la imagen por defecto -->

              <img
                class="rounded-borders cursor-pointer"
                :src="
                  product.images.length > 0
                    ? publicPath + '/ow/assets/' + product.images[0].image
                    : defaultImageApp
                "
                :alt="product.name"
                loading="lazy"
                clickable
                @click="showItem(product)"
                :disabled="points.points.points > product.value ? disabled : 0"
              />
            </div>

            <div class="text-subtitle1 text-left q-ml-md">
              {{ product.name }}
            </div>
            <div class="text-subtitle2 text-left q-ml-md">
              {{ product.value }} {{ settings.currency_abbreviation }}
            </div>
          </div>
        </div>
      </q-carousel-slide>
    </q-carousel>

    <!-- Dialog que se abre al dar click a la imagen -->
    <div>
      <ProductPage v-model="dialog" :product="products.id" :points="points" />
    </div>
  </div>
  <div v-else>{{ $t("BuyingProcess.products.no-products") }}</div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { ref } from "vue";
import { mapActions } from "vuex";
import settings from "@/mixins/settings";
import config from "../../../../public/config.json"

const ProductPage = defineAsyncComponent(() =>
  import("../../module-3-transactions/components/ProductPage.vue")
);

export default {
  mixins: [settings],

  components: {
    ProductPage,
  },
  props: {
    points: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      isData: false,
      defaultImage: this.defaultImageApp,
      disabled: null,
    };
  },
  computed: {
    products() {
      return this.$store.state.products.products;
    },
    // points() {
    //   return this.$store.state.points.points;
    // },
  },
  methods: {
    ...mapActions("products", ["getProducts"]),
    // ...mapActions("points", ["getPoints"]),

    // Abre el ProductPage y filtra por ID
    showItem(id) {
      this.dialog = true;
      this.products.id = id;
    },
  },

  setup() {
    return {
      slide: ref(1),
      publicPath: config.api.url,
    };
  },

  // mounted() {
  //   if (this.$i18n.locale === "es") {
  //     this.settings.localization.locales[0];
  //     // console.log(this.settings.localization.locales);
  //     console.log(this.settings.localization.locales[0]);
  //   }
  //   if (this.$i18n.locale === "en") {
  //     this.settings.localization.locales[1];
  //   }
  // },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

.q-img,
img {
  border-radius: 25px 25px 25px 0px;
  height: 177px;
  margin: 0.5rem;
  object-fit: contain;
  width: 160px;
}

.text-subtitle1 {
  color: $dark;
  font-size: 13px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 150px;
}

.text-subtitle2 {
  color: $gray2;
  font-size: 15px;
  font-weight: 400;
}

.title-products {
  color: v-bind("settings.color_accent");
}
</style>
